import React, { useState } from "react";

import LocaleItem from "./LocaleSelect/LocaleItem";

interface Props {
  locale: string;
  locales: Locale[];
}

export default function LocaleSelect(props: Props) {
  const { locale, locales } = props;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(!expanded);
  };

  const classNames = ["locale-select"];
  if (expanded) {
    classNames.push("expanded");
  }

  return (
    <div className={classNames.join(" ")}>
      <button className="toggle" onClick={handleToggle}>
        <span>Choose language</span>
      </button>
      {expanded && (
        <ul>
          {locales.map((l: Locale) => (
            <LocaleItem key={l.id} current={locale == l.id} {...l} />
          ))}
        </ul>
      )}
    </div>
  );
}
