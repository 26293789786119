// Entry point for the build script in your package.json

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

import "./features/externalLinks";
import "./features/nav";
import "./features/pastEvents";

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
