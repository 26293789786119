import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const link = document.querySelector(".show-all-events");

  if (link && link.parentNode) {
    link.addEventListener("click", function (evt: Event) {
      evt.preventDefault();
      (link.parentNode as HTMLElement).classList.add("show-all");
    });
  }
});
