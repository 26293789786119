import readyHandler from "../lib/readyHandler";
import WindowAnimation from "../lib/WindowAnimation";

readyHandler.ready(function () {
  const body = document.querySelector("body");
  const header = document.querySelector("header");
  const nav = document.querySelector("nav");

  const fakeHeader = document.createElement("header");
  const headerStyle = header.getAttribute("style");

  if (headerStyle) {
    fakeHeader.setAttribute("style", headerStyle);
  }
  fakeHeader.classList.add("fake");
  fakeHeader.innerHTML = header.innerHTML;
  header.parentNode.insertBefore(fakeHeader, header);

  let headerHeight = 0;
  let navHeight = 0;

  WindowAnimation.register({
    resize: function () {
      headerHeight = header.offsetHeight;
      navHeight = nav.offsetHeight;
    },
    scroll: function (pos) {
      if (pos >= navHeight) {
        body.classList.add("fixed-header-prepare");
      } else {
        body.classList.remove("fixed-header-prepare");
      }

      if (pos >= headerHeight - navHeight) {
        body.classList.add("fixed-header");
      } else {
        body.classList.remove("fixed-header");
      }
    }
  });
});
