import React from "react";

interface Props extends Locale {
  current: boolean;
}

export default function LocaleItem(props: Props) {
  const { name, localName, url, current } = props;

  const className = current ? "current" : "";

  return (
    <li className={className}>
      <a href={url}>
        <span className="name">{name}</span>
        {localName && <span className="local-name">{localName}</span>}
      </a>
    </li>
  );
}
